<template>
  <div v-if="data.data">
    <v-toolbar flat>
      <v-toolbar-title>
        UserName Changes
        <span class="green--text">
          {{ $store.state.userNameChanges.all.data.length | formatMoney }}</span
        >
      </v-toolbar-title>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :items="$store.state.userNameChanges.all.data"
      v-model="selected"
      :options.sync="options"
      show-select
      :items-per-page="1000"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:header.approve="{ header }">
        {{ header.text }}
        <v-icon x-large color="green" class="mr-10" @click="allApproveItem()"
          >done_all</v-icon
        >
      </template>
      <template v-slot:header.reject="{ header }">
        {{ header.text }}
        <v-icon x-large color="red" class="mr-10" @click="allRejectItem()"
          >done_all</v-icon
        >
      </template>
      <template v-slot:item.reject="{ item }">
        <v-icon x-large color="red" class="mr-10" @click="rejectItem(item)"
          >link_off</v-icon
        >
      </template>
      <template v-slot:item.approve="{ item }">
        <v-icon x-large color="green" class="mr-2" @click="approveItem(item)"
          >done</v-icon
        >
      </template>
      <template v-slot:item.id="{ item }">
        <UserDetailCard
          :userId="item.id"
          :pictureUrl="item.pictureUrl"
          :userName="item.userName"
          :level="item.level"
        />
      </template>
      <template v-slot:item.locationInfo="{ item }">
        <div style="display: flex;">
          <v-avatar size="36" style="margin-right:5px; margin-top:3px;">
            <v-img
              :src="
                `https://pokerface.restplay.com/flags/${item.locationInfo.country_short.toLocaleLowerCase()}.png`
              "
            />
          </v-avatar>
          <div>
            {{ item.locationInfo.country_long }} - {{ item.locationInfo.city }}
            <div>{{ item.ipAdress.split(",")[0] }}</div>
          </div>
        </div>
      </template>
      <template v-slot:item.pictureUrl="{ item }">
        <div style="display: flex;">
           <a target="_blank"
              :href="`https://pokerface.restplay.com${item.pictureUrl}`">
          <v-avatar size="200" style="margin-right:5px; margin-top:3px;">
            <v-img
              v-if="item.pictureUrl.startsWith('/')"
              :src="`https://pokerface.restplay.com${item.pictureUrl}`"
            />
            <v-img
              v-else-if="item.pictureUrl && !item.pictureUrl.startsWith('http')"
              :src="`https://pokerface.restplay.com/Avatars/${item.pictureUrl}`"
            />
            <v-img v-else :src="`${item.pictureUrl}`" />
          </v-avatar>
            </a>

        </div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        :total-visible="20"
        @input="initialize"
        :length="data.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  data() {
    return {
      page: 1,
      total: 0,
      options: {},
      pageCount: 0,
      currentPage: 1,
      data: {},
      itemsPerPage: 500,
      singleSelect: false,
      selected: [],
      pagination: {
        sortBy: "id",
      },
      headers: [
        { text: "Name", value: "id" },
        { text: "Location", value: "locationInfo" },
        { text: "Reject", value: "reject", sortable: false },
        { text: "New Name", value: "newUserName" },
        { text: "Approve", value: "approve", sortable: false },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        if (this.options.sortBy.length > 0) this.initialize();
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    async initialize(page) {
      // eslint-disable-next-line no-console
      console.log(page);
      this.data = await this.$store.dispatch("userNameChanges/getAll", {
        page: (page || this.currentPage) - 1,
        options: this.options,
      });
    },
    async rejectItem(item) {
      await this.$store.dispatch("userReports/postBannedToUser", {
        userId: item.id,
        status: -1,
        reasonCode: 1,
        isChipDown: false,
        timeLeft: 1,
      });
      await this.$store.dispatch("userNameChanges/rejectPicture", item.id);
      // this.$store.dispatch("userNameChanges/getAll", { page: 0 });
    },
    async allRejectItem() {
      this.selected.forEach(async (m) => {
        await this.$store.dispatch("userReports/postBannedToUser", {
          userId: m.id,
          status: -1,
          reasonCode: 1,
          isChipDown: false,
          timeLeft: 1,
        });
        await this.$store.dispatch("userNameChanges/rejectPicture", m.id);
      });
      // this.$store.dispatch("userNameChanges/getAll", { page: 0 });
    },
    async approveItem(item) {
      // eslint-disable-next-line no-console
      console.log(this.selected);
      await this.$store.dispatch("userNameChanges/approvePicture", item.id);
      // this.$store.dispatch("userNameChanges/getAll", { page: 0 });
    },
    async allApproveItem() {
      this.selected.forEach(async (m) => {
        await this.$store.dispatch("userNameChanges/approvePicture", m.id);
      });
      // this.$store.dispatch("userNameChanges/getAll", { page: 0 });
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>
